import React from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import styles from './EventTitle.module.scss';

const EventTitle = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const updateTitle = (e) => {
    dispatch({
      type: 'UPDATE_TITLE',
      title: e.target.value,
    });
  }

  const updateShortDescription = (e) => {
    dispatch({
      type: 'UPDATE_SHORT_DESCRIPTION',
      shortDescription: e.target.value,
    });
  }

  const presentationMode = () => {
    return state.loading ? (
      <p class={styles.title}>Loading...</p>
    ) : (
      <p class={styles.title}>{state.event.title}</p>
    );
  };

  const editMode = () => {
    return (
      <>
        <input
          id={styles.titleInput}
          type="text"
          placeholder="Event Title Here..."
          onChange={updateTitle}
        />
        <input
          id={styles.titleInput}
          type="text"
          placeholder="Event Short Description Here..."
          onChange={updateShortDescription}
        />
      </>
    );
  };

  return (
    <div class={styles.event}>
      {state.inEditMode ?
        (
          editMode()
        ) : (
          presentationMode()
        )
      }
    </div>
  );
};

export default EventTitle;

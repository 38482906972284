import React from "react";

import { applyFormatToSelectedRange, applyStyleToSelectedRange } from "../../Discourse/components/RichTextEditerHelpers";

import styles from './EventDescription.module.scss';

const EventDescriptionFormattingControls = ({ contentEditable, setState }) => {
  return (
    <>
      {contentEditable && (
        <div className={styles.stylingTool}>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'b' },
                  setState: setState
                })
              }
            }
          >
            B
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'i' },
                  setState: setState
                })
              }
            }
          >
            I
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'a', href: null },
                  setState: setState
                })
              }
            }
          >
            L
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentTitle' },
                  setState: setState
                })
              }
            }
          >
            T
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSubtitle' },
                  setState: setState
                })
              }
            }
          >
            ST
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSectionTitle', size: 2 },
                  setState: setState
                })
              }
            }
          >
            BH
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentSectionTitle', size: 3 },
                  setState: setState
                })
              }
            }
          >
            SH
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentEmphasis' },
                  setState: setState
                })
              }
            }
          >
            E
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyStyleToSelectedRange({
                  style: { type: 'sup' },
                  setState: setState
                })
              }
            }
          >
            S
          </button>
          <button
            className={styles.stylingBtn}
            onClick={
              () => {
                applyFormatToSelectedRange({
                  format: { type: 'DocumentCitationsSection' },
                  setState: setState
                })
              }
            }
          >
            OL
          </button>
        </div>
      )}
    </>
  );
};

export default EventDescriptionFormattingControls;

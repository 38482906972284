import React from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import { createEvent } from './restApi';

import styles from './EventPublishingWorkflowControls.module.scss';

const EventPublishingWorkflowControls = ({ documentManagerWrapperState }) => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  return (
    <div id={styles.eventPublishingWorkflowControlsContainer}>
      <div />
      {state.inEditMode && (
        <button
          className={styles.btn}
          onClick={() => {

            createEvent({
              onResponse: onResponse,
              onError: onError,
              body: {
                authenticityToken,
                event: {
                  ...state.event,
                  description: documentManagerWrapperState.documentManager.getCurrentDocument().asJSON(),
                },
              },
            });
          }}
        >
          Save
        </button>
      )}
      {/* {!state.inEditMode && (
        <button
          className={styles.btn}
          onClick={() => {}}
        >
          Edit
        </button>
      )} */}
    </div>
  );
};

export default EventPublishingWorkflowControls;

import React from 'react';

import styles from './DiscourseNavigation.module.scss';

const PublishedDiscourseNavigation = ({ authenticatedUser }) => {
  return (
    <div className={styles.discourseNavigation}>
      <div />
      {authenticatedUser?.canAdministerDocuments && (
        <button
          className={styles.discourseNavigationButton}
          onClick={() => { window.location.href = '/discourse/admin'; }}
        >
          Admin Discourse
        </button>
      )}
      <button
        className={styles.discourseNavigationButton}
        onClick={() => { window.location.href = '/discourse/your'; }}
      >
        Your Discourse
      </button>
      <button
        className={styles.discourseNavigationButton}
        onClick={() => { window.location.href = '/discourse/new'; }}
      >
        New
      </button>
    </div>
  );
};

export default PublishedDiscourseNavigation;

import React, { useEffect } from 'react';

import {
  useEventState,
} from './EventContext';

import {
  setCursorPositionInDOM
} from '../../Discourse/components/RichTextEditerHelpers';

import EventDescriptionFormattingControls from './EventDescriptionFormattingControls';
import EventDescriptionRichTextEditor from './EventDescriptionRichTextEditor';
import EventDescriptionDebugger from './EventDescriptionDebugger';

import styles from './EventDescription.module.scss';

const EventDescription = ({
  documentManagerWrapperState,
  setDocumentManagerWrapperState,
}) => {
  const state = useEventState();

  useEffect(() => {
    if (state?.event?.description) {
      setDocumentManagerWrapperState(prevState => {
        const nextState = { ...prevState };
        nextState.documentManager.addDocumentToHistoryFromData({ document: state?.event?.description });
        return nextState;
      });
    }
  }, [state?.event?.description]);

  useEffect(() => {
    setCursorPositionInDOM({
      editableContentId: styles.mainSection,
      isibuteDocument: documentManagerWrapperState.documentManager.getCurrentDocument(),
    });
  }, [documentManagerWrapperState.documentManager.historyIndex]);

  const eventDescriptionHasContent = () => {
    if (!Object.entries(state?.event?.description?.documentComponents || {}).length) {
      return false;
    }

    let contentLength = 0;

    const documentComponents = Object.values(state?.event?.description?.documentComponents);

    documentComponents.forEach((documentComponent) => {
      contentLength += documentComponent.unstyledContent.length;
    });

    return contentLength > documentComponents.length;
  }

  return (
    <div class={styles.event}>
      <EventDescriptionFormattingControls contentEditable={state.inEditMode} setState={setDocumentManagerWrapperState} />
      {eventDescriptionHasContent() && (
        <h3 class={styles.descriptionHeading}>Event Description:</h3>
      )}
      <EventDescriptionRichTextEditor
        authenticatedUser={state.authenticatedUser}
        setState={setDocumentManagerWrapperState}
        state={documentManagerWrapperState}
        contentEditable={state.inEditMode}
      />
      <EventDescriptionDebugger state={documentManagerWrapperState} />
    </div>
  );
};

export default EventDescription;

import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";
import { convertObjectKeysToSnakeCase } from "../../Shared/ConvertObjectKeysToSnakeCase";

export const approveDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const denyDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getDocument = ({ documentId, onResponse, onError }) => {
  fetch(
    `/api/documents/${documentId}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const requestRevisionsForDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const saveDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const submitDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getPublishedDocuments = ({ pageNumber, onResponse, onError }) => {
  fetch(
    `/api/documents/published?page_number=${pageNumber}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getDocumentsForUser = ({
  viewState,
  pageNumber,
  onResponse,
  onError
}) => {
  fetch(
    `/api/documents/for_user?view_state=${viewState}&page_number=${pageNumber}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getDocumentsForAdmin = ({
  viewState,
  pageNumber,
  onResponse,
  onError
}) => {
  fetch(
    `/api/documents/for_admin?view_state=${viewState}&page_number=${pageNumber}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const seedDocument = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/seed",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

import React from 'react';

import {
  CareerBuilderRequestsProvider,
} from './CareerBuilderRequestsContext';

import CareerBuilderRequestsFeedback from './CareerBuilderRequestsFeedback';
import CareerBuilderRequestsTable from './CareerBuilderRequestsTable';

import styles from './CareerBuilderRequests.module.scss';

const CareerBuilderRequests = () => {
  return (
    <CareerBuilderRequestsProvider>
      <CareerBuilderRequestsFeedback />
      <section>
        <h2 className={styles.tableTitle}>
          Career Builder Requests
        </h2>
        <CareerBuilderRequestsTable />
      </section>
    </CareerBuilderRequestsProvider>
  );
};

export default CareerBuilderRequests;

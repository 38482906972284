import React from 'react';

import {
  useTeamBuilderRequestsState,
  useTeamBuilderRequestsDispatch
} from './TeamBuilderRequestsContext';

import Feedback from '../../Feedback/components/Feedback';

const TeamBuilderRequestsFeedback = () => {
  const teamBuilderRequestsState = useTeamBuilderRequestsState();

  const teamBuilderRequestsDispatch = useTeamBuilderRequestsDispatch();

  const clearFeedback = () => {
    teamBuilderRequestsDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={teamBuilderRequestsState?.feedbackMessage}
      variant={teamBuilderRequestsState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default TeamBuilderRequestsFeedback;

import React from 'react';

import {
  UserInvitationsProvider,
} from './UserInvitationsContext';

import UserInvitationsFeedback from './UserInvitationsFeedback';
import NewOpenUserInvitationsForm from './NewOpenUserInvitationsForm';
import OpenUserInvitationsTable from './OpenUserInvitationsTable';
import UserInvitationRequestsTable from './UserInvitationRequestsTable';

import styles from './UserInvitations.module.scss';

const UserInvitations = () => {
  return (
    <UserInvitationsProvider>
      <UserInvitationsFeedback />
      <section>
        <h2 className={styles.tableTitle}>
          Generate And Send Open User Invitations
        </h2>
        <NewOpenUserInvitationsForm />
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Open User Invitations
        </h2>
        <OpenUserInvitationsTable />
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          User Invitation Requests
        </h2>
        <UserInvitationRequestsTable />
      </section>
    </UserInvitationsProvider>
  );
}

export default UserInvitations;

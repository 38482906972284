import React from 'react';

import {
  useCareerBuilderRequestsState,
  useCareerBuilderRequestsDispatch
} from './CareerBuilderRequestsContext';

import Feedback from '../../Feedback/components/Feedback';

const CareerBuilderRequestsFeedback = () => {
  const careerBuilderRequestsState = useCareerBuilderRequestsState();

  const careerBuilderRequestsDispatch = useCareerBuilderRequestsDispatch();

  const clearFeedback = () => {
    careerBuilderRequestsDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={careerBuilderRequestsState?.feedbackMessage}
      variant={careerBuilderRequestsState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default CareerBuilderRequestsFeedback;

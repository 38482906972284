import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";
import { convertObjectKeysToSnakeCase } from "../../Shared/ConvertObjectKeysToSnakeCase";

export const createEvent = ({ onResponse, onError, body }) => {
  fetch(
    "/api/events",
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getEvents = ({ lastPageViewed, onResponse, onError }) => {
  fetch(
    `/api/events?last_page_viewed=${lastPageViewed}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getEvent = ({ id, onResponse, onError }) => {
  fetch(
    `/api/events/${id}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const addEventAttendee = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/add_event_attendee`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const generateAndSendEventInvitation = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/generate_and_send_invite`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getAuthenticatedEventAttendeeByAuthenticatedUser = ({ id, onResponse, onError }) => {
  fetch(
    `/api/events/${id}/authenticated_event_attendee_by_authenticated_user`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getAuthenticatedEventAttendeeByInviteEmailAndInviteCode = ({ id, onResponse, onError, inviteEmail, inviteCode }) => {
  fetch(
    `/api/events/${id}/authenticated_event_attendee_by_invite_email_and_invite_code?invite_email=${inviteEmail}&invite_code=${inviteCode}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const acceptEventInvitation = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/accept_invitation`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const declineEventInvitation = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/decline_invitation`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const closeEventInvitation = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/close_invitation`,
    {
      method: HTTPRequestMethods.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const recordAttendance = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/record_attendance`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const stopEventInvites = ({ id, onResponse, onError, body }) => {
  fetch(
    `/api/events/${id}/stop_event_invites`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

import React, { useState, useEffect } from 'react';
import PublishedDiscourseNavigation from './PublishedDiscourseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListOfDiscourse from './ListOfDiscourse';
import Feedback from '../../Feedback/components/Feedback';

import { getPublishedDocuments } from './restApi';

import styles from './DiscourseIndex.module.scss';

const PublishedDiscourse = ({ authenticatedUser }) => {
  const [state, setState] = useState({
    documents: [],
    pageNumber: 1,
    moreDocuments: true,
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const clearFeedback = () => {
    setState(prevState => ({
      ...prevState,
      feedbackMessage: null,
      feedbackVariant: null,
    }));
  };

  const onResponse = response => setState(prevState => ({ ...prevState, ...response, documents: prevState.documents.concat(response.documents) }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  useEffect(() => {
    getPublishedDocuments({
      pageNumber: state.pageNumber,
      onResponse,
      onError,
    });
  }, []);

  return (
    <>
      {authenticatedUser?.inDiscourseExperiment && (
        <section className={styles.pageSection}>
          <PublishedDiscourseNavigation authenticatedUser={authenticatedUser} />
        </section>
      )}
      <InfiniteScroll
        dataLength={state.documents.length}
        next={() => {
          getPublishedDocuments({
            pageNumber: state.pageNumber + 1,
            onResponse,
            onError,
          });
        }}
        hasMore={state.moreDocumentsAvailable}
        loader={
          <h4 style={{ textAlign: 'center' }}>Loading...</h4>
        }
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>You've seen all published discourse</b>
          </p>
        }
        scrollableTarget="html"
      >
        <ListOfDiscourse discourse={state.documents} />
      </InfiniteScroll>
      <Feedback
        message={state.feedbackMessage}
        variant={state.feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </>
  );
};

export default PublishedDiscourse;

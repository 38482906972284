import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const getBiopharmaGroups = ({ onResponse, onError, pageNumber }) => {
  fetch(
    `/api/biopharma_groups?page_number=${pageNumber}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

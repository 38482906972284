import React from 'react';

import {
  BiopharmaGroupsProvider,
} from './BiopharmaGroupsContext';

import BiopharmaGroupsFeedback from './BiopharmaGroupsFeedback';
import BiopharmaGroupsTable from './BiopharmaGroupsTable';

import styles from './BiopharmaGroups.module.scss';

const BiopharmaGroups = () => {
  return (
    <BiopharmaGroupsProvider>
      <BiopharmaGroupsFeedback />
      <section>
        <h2 className={styles.tableTitle}>
          Biopharma Groups
        </h2>
        <BiopharmaGroupsTable />
      </section>
    </BiopharmaGroupsProvider>
  );
};

export default BiopharmaGroups;

import { createContext, useContext, useReducer } from 'react';

const TeamBuilderRequestsStateContext = createContext(null);

const TeamBuilderRequestsDispatchContext = createContext(null);

export function TeamBuilderRequestsProvider({ children }) {
  const initialTeamBuilderRequestsState = {
    newOpenUserInvitation: {
      firstName: '',
      lastName: '',
      email: '',
    },
    openTeamBuilderRequests: [],
    userInvitationRequests: [],
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [teamBuilderRequestsState, teamBuilderRequestsDispatch] = useReducer(
    teamBuilderRequestsReducer,
    initialTeamBuilderRequestsState
  );

  return (
    <TeamBuilderRequestsStateContext.Provider value={teamBuilderRequestsState}>
      <TeamBuilderRequestsDispatchContext.Provider value={teamBuilderRequestsDispatch}>
        {children}
      </TeamBuilderRequestsDispatchContext.Provider>
    </TeamBuilderRequestsStateContext.Provider>
  );
}

export function useTeamBuilderRequestsState() {
  return useContext(TeamBuilderRequestsStateContext);
}

export function useTeamBuilderRequestsDispatch() {
  return useContext(TeamBuilderRequestsDispatchContext);
}

function teamBuilderRequestsReducer(teamBuilderRequestsState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...teamBuilderRequestsState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...teamBuilderRequestsState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...teamBuilderRequestsState, ...clearedFeedback };
    }
    case 'UPDATE_FIRST_NAME': {
      return {
        ...teamBuilderRequestsState,
        newOpenUserInvitation: {
          ...teamBuilderRequestsState.newOpenUserInvitation,
          firstName: action.firstName,
        }
      };
    }
    case 'UPDATE_LAST_NAME': {
      return {
        ...teamBuilderRequestsState,
        newOpenUserInvitation: {
          ...teamBuilderRequestsState.newOpenUserInvitation,
          lastName: action.lastName,
        }
      };
    }
    case 'UPDATE_EMAIL': {
      return {
        ...teamBuilderRequestsState,
        newOpenUserInvitation: {
          ...teamBuilderRequestsState.newOpenUserInvitation,
          email: action.email,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

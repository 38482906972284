import React from 'react';

import {
  useBiopharmaGroupsState,
  useBiopharmaGroupsDispatch
} from './BiopharmaGroupsContext';

import Feedback from '../../Feedback/components/Feedback';

const BiopharmaGroupsFeedback = () => {
  const biopharmaGroupsState = useBiopharmaGroupsState();

  const biopharmaGroupsDispatch = useBiopharmaGroupsDispatch();

  const clearFeedback = () => {
    biopharmaGroupsDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={biopharmaGroupsState?.feedbackMessage}
      variant={biopharmaGroupsState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default BiopharmaGroupsFeedback;

import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const getCareerBuilderRequests = ({ onResponse, onError }) => {
  fetch(
    `/api/career_builder_requests`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const resolveCareerBuilderRequest = ({ userInvitationRequest, onResponse, onError, body }) => {
  fetch(
    `/api/user_invitation_requests/${userInvitationRequest.id}/approve`,
    {
      method: HTTPRequestMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

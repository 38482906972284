import React, { useEffect } from 'react';

import {
  useTeamBuilderRequestsState,
  useTeamBuilderRequestsDispatch
} from './TeamBuilderRequestsContext';

import {
  resolveTeamBuilderRequest,
  getTeamBuilderRequests,
} from './restApi';

import styles from './TeamBuilderRequests.module.scss';

const TeamBuilderRequestsTable = () => {
  const teamBuilderRequestsState = useTeamBuilderRequestsState();

  const teamBuilderRequestsDispatch = useTeamBuilderRequestsDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => teamBuilderRequestsDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => teamBuilderRequestsDispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    getTeamBuilderRequests({ onResponse, onError });
  }, []);

  return teamBuilderRequestsState?.teamBuilderRequests?.length ? (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>LinkedIn</th>
          <th>Website</th>
          <th>State</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {teamBuilderRequestsState?.teamBuilderRequests?.map(request => {
          return (
            <tr key={request.id}>
              <td>{request.firstName} {request.lastName}</td>
              <td>{request.email}</td>
              <td>{request.linkedinProfileUrl}</td>
              <td>{request.personalBlogOrWebsiteUrl}</td>
              <td>{request.aasmState}</td>
              <td>{request.createdAt}</td>
              <td>
                <button
                  onClick={() => {
                      resolveTeamBuilderRequest(({
                        teamBuilderRequest: request,
                        onResponse,
                        onError,
                        body: {
                          authenticity_token: authenticityToken,
                        },
                      }));
                    }
                  }
                  className={styles.approveInvitationRequestBtn}
                >
                  Resolve
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p className={styles.noContentMessage}>No unresolved team builder requests.</p>
  );
};

export default TeamBuilderRequestsTable;

import React from 'react';

import {
  monthNameFromNumber,
  meridiemIndicatorForHour,
  hourForTwelveHourClock,
  formattedMinute,
} from '../../Shared/timeHelpers';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import styles from './EventDetails.module.scss';

const EventDetails = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const updateStartDay = (e) => {
    dispatch({
      type: 'UPDATE_START_DATE',
      startYear: e.target.value.split("-")[0],
      startMonth: e.target.value.split("-")[1],
      startDay: e.target.value.split("-")[2],
    });
  };

  const updateEndDay = (e) => {
    dispatch({
      type: 'UPDATE_END_DATE',
      endYear: e.target.value.split("-")[0],
      endMonth: e.target.value.split("-")[1],
      endDay: e.target.value.split("-")[2],
    });
  };

  const updateStartTime = (e) => {
    dispatch({
      type: 'UPDATE_START_TIME',
      startHour: e.target.value.split(":")[0],
      startMinute: e.target.value.split(":")[1],
    });
  };

  const updateEndTime = (e) => {
    dispatch({
      type: 'UPDATE_END_TIME',
      endHour: e.target.value.split(":")[0],
      endMinute: e.target.value.split(":")[1],
    });
  };

  const updateTimezone = (e) => {
    dispatch({
      type: 'UPDATE_TIMEZONE',
      timezoneName: e.target.value,
    });
  };

  const updateLocation = (e) => {
    dispatch({
      type: 'UPDATE_LOCATION',
      location: e.target.value,
    });
  };

  const presentationMode = () => {
    return state.loading ? (
      null
    ) : (
      <div class={styles.event}>
        <div class={styles.textContainer}>
          <div id={styles.eventDetailsContainer}>
            <h3 id={styles.eventDetailsHeading}>Event Details:</h3>
            <p class={styles.eventDetails}>{`📅 Date: ${monthNameFromNumber(state.event.startMonth)} ${state.event.startDay}, ${state.event.startYear}`}</p>
            <p class={styles.eventDetails}>{`🕕 Time: ${hourForTwelveHourClock(state.event.startHour)}:${formattedMinute(state.event.startMinute)} ${meridiemIndicatorForHour(state.event.startHour)} - ${hourForTwelveHourClock(state.event.endHour)}:${formattedMinute(state.event.endMinute)} ${meridiemIndicatorForHour(state.event.endHour)} ${state.event.timezoneName}`}</p>
            <p class={styles.eventDetails}>{`📍 ${state.event.location}`}</p>
          </div>
        </div>
      </div>
    );
  };

  const editMode = () => {
    return (
      <>
        {/* <label className={styles.inputLabel}>Type Of Event</label>
        <select className={styles.input} onChange={(e) => { setVenueType(e.target.value) }}>
          <option value="online" selected>Online</option>
          <option value="in_person">In Person</option>
          <option value="hybrid">Online and In Person</option>
        </select> */}
        <h3 id={styles.eventDetailsHeading}>Event Details:</h3>
        <label className={styles.inputLabel}>Start Date</label>
        <input
          className={styles.input}
          type="date"
          onChange={updateStartDay}
        />
        <label className={styles.inputLabel}>End Date</label>
        <input
          className={styles.input}
          type="date"
          onChange={updateEndDay}
        />
        <label className={styles.inputLabel}>Start Time</label>
        <input
          className={styles.input}
          type="time"
          onChange={updateStartTime}
        />
        <label className={styles.inputLabel}>End Time</label>
        <input
          className={styles.input}
          type="time"
          onChange={updateEndTime}
        />
        <label className={styles.inputLabel}>Timezone</label>
        <select className={styles.input} onChange={updateTimezone}>
          {state.timezones.map((timezone) => {
            if (timezone.name == state?.event?.timezoneName) {
              return <option value={timezone.name} selected>{timezone.display_name}</option>;
            } else {
              return <option value={timezone.name}>{timezone.display_name}</option>;
            }
          })}
        </select>
        <label className={styles.inputLabel}>Location</label>
        <input
          className={styles.input}
          type="text"
          onChange={updateLocation}
        />
      </>
    );
  };

  return (
    <div class={styles.event}>
      {state.inEditMode ?
        (
          editMode()
        ) : (
          presentationMode()
        )
      }
    </div>
  );
};

export default EventDetails;

import React from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import {
  hourForTwelveHourClock,
  meridiemIndicatorForHour,
  formattedMinute,
} from '../../Shared/timeHelpers';

import styles from './EventBreakdown.module.scss';

const EventBreakdown = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const updateNewBreakdownItemStartDay = (e) => {
    dispatch({
      type: 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_START_DATE',
      startYear: e.target.value.split("-")[0],
      startMonth: e.target.value.split("-")[1],
      startDay: e.target.value.split("-")[2],
    });
  };

  const updateNewBreakdownItemEndDay = (e) => {
    dispatch({
      type: 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_END_DATE',
      endYear: e.target.value.split("-")[0],
      endMonth: e.target.value.split("-")[1],
      endDay: e.target.value.split("-")[2],
    });
  };

  const updateNewBreakdownItemStartTime = (e) => {
    dispatch({
      type: 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_START_TIME',
      startHour: e.target.value.split(":")[0],
      startMinute: e.target.value.split(":")[1],
    });
  };

  const updateNewBreakdownItemEndTime = (e) => {
    dispatch({
      type: 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_END_TIME',
      endHour: e.target.value.split(":")[0],
      endMinute: e.target.value.split(":")[1],
    });
  };

  const updateNewBreakdownItemDescription = (e) => {
    dispatch({
      type: 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_DESCRIPTION',
      description: e.target.value,
    });
  };

  const addNewEventBreakdownItem = () => {
    dispatch({
      type: 'ADD_NEW_EVENT_BREAKDOWN_ITEM'
    });
  };

  const presentationMode = () => {
    return state.loading ? (
      null
    ) : (
      <div class={styles.textContainer}>
        <div id={styles.breakdownContainer}>
          {!!state.event.breakdownItems.length && (
            <h3 class={styles.breakdownHeading}>Event Breakdown:</h3>
          )}
          {state.event.breakdownItems.map((breakdownItem) => {
            const startTime = `${hourForTwelveHourClock(breakdownItem.startHour)}:${formattedMinute(breakdownItem.startMinute)} ${meridiemIndicatorForHour(breakdownItem.startHour)}`;
            const endTime = `${hourForTwelveHourClock(breakdownItem.endHour)}:${formattedMinute(breakdownItem.endMinute)} ${meridiemIndicatorForHour(breakdownItem.endHour)}`;
            return <p class={styles.breakdown}>{`${startTime} - ${endTime}: ${breakdownItem.description}`}</p>;
          })}
        </div>
      </div>
    );
  };

  const editMode = () => {
    return (
      <div class={styles.textContainer}>
        <div id={styles.breakdownContainer}>
          <h3 class={styles.breakdownHeading}>Event breakdown:</h3>
          {state.event.breakdownItems.map((breakdownItem) => {
            const startTime = `${hourForTwelveHourClock(breakdownItem.startHour)}:${formattedMinute(breakdownItem.startMinute)} ${meridiemIndicatorForHour(breakdownItem.startHour)}`;
            const endTime = `${hourForTwelveHourClock(breakdownItem.endHour)}:${formattedMinute(breakdownItem.endMinute)} ${meridiemIndicatorForHour(breakdownItem.endHour)}`;
            return <p class={styles.breakdown}>{`${startTime} - ${endTime}: ${breakdownItem.description}`}</p>;
          })}
          <label className={styles.inputLabel}>Start Date</label>
          <input
            className={styles.input}
            type="date"
            value={`${state.newEventBreakdownItem.startYear}-${state.newEventBreakdownItem.startMonth}-${state.newEventBreakdownItem.startDay}`}
            onChange={updateNewBreakdownItemStartDay}
          />
          <label className={styles.inputLabel}>End Date</label>
          <input
            className={styles.input}
            type="date"
            value={`${state.newEventBreakdownItem.endYear}-${state.newEventBreakdownItem.endMonth}-${state.newEventBreakdownItem.endDay}`}
            onChange={updateNewBreakdownItemEndDay}
          />
          <label className={styles.inputLabel}>Start Time</label>
          <input
            className={styles.input}
            type="time"
            value={`${state.newEventBreakdownItem.startHour}:${state.newEventBreakdownItem.startMinute}`}
            onChange={updateNewBreakdownItemStartTime}
          />
          <label className={styles.inputLabel}>End Time</label>
          <input
            className={styles.input}
            type="time"
            value={`${state.newEventBreakdownItem.endHour}:${state.newEventBreakdownItem.endMinute}`}
            onChange={updateNewBreakdownItemEndTime}
          />
          <label className={styles.inputLabel}>Description</label>
          <input
            className={styles.input}
            type="text"
            placeholder="Description..."
            value={state.newEventBreakdownItem.description}
            onChange={updateNewBreakdownItemDescription}
          />
          <button
            type="button"
            className={styles.addNewEventBreakdownItemBtn}
            onClick={addNewEventBreakdownItem}
          >
            Add New Breakdown Item
          </button>
        </div>
      </div>
    );
  };

  return (
    <div class={styles.event}>
      {state.inEditMode ?
        (
          editMode()
        ) : (
          presentationMode()
        )
      }
    </div>
  );
};

export default EventBreakdown;

export const hourForTwelveHourClock = (hour) => {
  return {
    '0': 12,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
    '13': 1,
    '14': 2,
    '15': 3,
    '16': 4,
    '17': 5,
    '18': 6,
    '19': 7,
    '20': 8,
    '21': 9,
    '22': 10,
    '23': 11,
  }[hour];
};

export const formattedMinute = (minute) => {
  return (minute > 9) ? minute : `0${minute}`;
}

export const meridiemIndicatorForHour = (hour) => {
  return (hour < 12) ? 'AM' : 'PM';
};

export const monthNameFromNumber = (number) => {
  return {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  }[number];
}

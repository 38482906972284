import React from 'react';

import {
  TeamBuilderRequestsProvider,
} from './TeamBuilderRequestsContext';

import TeamBuilderRequestsFeedback from './TeamBuilderRequestsFeedback';
import TeamBuilderRequestsTable from './TeamBuilderRequestsTable';

import styles from './TeamBuilderRequests.module.scss';

const TeamBuilderRequests = () => {
  return (
    <TeamBuilderRequestsProvider>
      <TeamBuilderRequestsFeedback />
      <section>
        <h2 className={styles.tableTitle}>
          Team Builder Requests
        </h2>
        <TeamBuilderRequestsTable />
      </section>
    </TeamBuilderRequestsProvider>
  );
};

export default TeamBuilderRequests;

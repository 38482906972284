import React from 'react';

import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({ dropdownMenuOptions }) => {
  const selectedOption = dropdownMenuOptions.find(option => option.isSelected );
  const defaultText = 'Select An Option';

  return (
    <div className={styles.dropdown}>
      <button className={styles.dropbtn}>{selectedOption?.text || defaultText}</button>
      <div className={styles.dropdownContent}>
        {dropdownMenuOptions.map((option) => {
          return <a key={option.text} onClick={option.onClick}>{option.text}</a>;
        })}
      </div>
    </div>
  );
};

export default DropdownMenu;

import React from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import styles from './EventMainImage.module.scss';

const EventMainImage = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const base64Image = (image) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data", "")
        .replace(/^.+,/, "");

      dispatch({
        type: 'UPDATE_IMAGE_AND_IMAGE_FILE_NAME',
        image: base64String,
        imageFileName: image.name,
      });
    };

    reader.readAsDataURL(image);
  };

  const clickHiddenImageInputButton = () => {
    document.getElementById(styles.imageInput).click();
  };

  const presentationMode = () => {
    return (
      <div id={styles.imageContainer}>
        <img class={styles.img} src={state.event.imagePath} />
      </div>
    );
  };

  const editMode = () => {
    return (
      <div
        id={styles.imageContainer}
        onClick={clickHiddenImageInputButton}
      >
        {state.event.image && (
          <img class={styles.img} src={`data:image/gif;base64,${state.event.image}`} />
        )}
        <input
          id={styles.imageInput}
          type="file"
          accept="image/png,image/gif,image/jpeg"
          onChange={(e) => { base64Image(e.target.files[0]) }}
        />
      </div>
    );
  };

  return (
    <div class={styles.event}>
      {state.inEditMode ?
        (
          editMode()
        ) : (
          presentationMode()
        )
      }
    </div>
  );
};

export default EventMainImage;

import React from 'react';
import DropdownMenu from '../../DropdownMenu/components/DropdownMenu';

import styles from './DiscourseNavigation.module.scss';

const YourDiscourseNavigation = ({
  authenticatedUser,
  state,
  setState
}) => {
  const dropdownMenuOptions = [
    {
      text: 'Your Drafts',
      onClick: () => {
        setState(prevState => {
          return {
            ...prevState,
            viewState: 'draft',
          };
        });
      },
      viewState: 'draft',
    },
    {
      text: 'Your Discourse Pending Approval',
      onClick: () => {
        setState(prevState => {
          return {
            ...prevState,
            viewState: 'pending_approval',
          };
        });
      },
      viewState: 'pending_approval',
    },
    {
      text: 'Your Discourse Pending Revision',
      onClick: () => {
        setState(prevState => {
          return {
            ...prevState,
            viewState: 'pending_revision',
          };
        });
      },
      viewState: 'pending_revision',
    },
    {
      text: 'Your Published Discourse',
      onClick: () => {
        setState(prevState => {
          return {
            ...prevState,
            viewState: 'published',
          };
        });
      },
      viewState: 'published',
    },
    {
      text: 'Your Denied Discourse',
      onClick: () => {
        setState(prevState => {
          return {
            ...prevState,
            viewState: 'denied',
          };
        });
      },
      viewState: 'denied',
    },
  ];

  dropdownMenuOptions.forEach((option) => {
    option.isSelected = option.viewState == state.viewState;
  });

  return (
    <div className={styles.discourseNavigation}>
      <DropdownMenu dropdownMenuOptions={dropdownMenuOptions}/>
      {authenticatedUser?.canAdministerDocuments && (
        <button
          className={styles.discourseNavigationButton}
          onClick={() => { window.location.href = '/discourse/admin'; }}
        >
          Admin Discourse
        </button>
      )}
      <button
        className={styles.discourseNavigationButton}
        onClick={() => { window.location.href = '/discourse/published'; }}
      >
        Published Discourse
      </button>
      <button
        className={styles.discourseNavigationButton}
        onClick={() => { window.location.href = '/discourse/new'; }}
      >
        New
      </button>
    </div>
  );
};

export default YourDiscourseNavigation;

import React from "react";

import {
  approveDocument,
  denyDocument,
  requestRevisionsForDocument,
  saveDocument,
  submitDocument,
} from "../../DiscourseIndex/components/restApi";

import styles from './DiscoursePublishingWorkflowControls.module.scss';

const DiscoursePublishingWorkflowControls = ({
  authenticatedUser,
  contentEditable,
  setContentEditable,
  state,
  setState,
}) => {
  const showAdminButtons = authenticatedUser?.canEditAllDocuments;
  const showAuthorButtons = authenticatedUser?.canEditAllDocuments;
  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));
  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  return (
    <div id={styles.editBtnContainer}>
      <div />
      {showAdminButtons && (
        <button
          className={styles.editBtn}
          onClick={() => {
            requestRevisionsForDocument({
              onResponse,
              onError,
              body: {
                documentManagerId: state.documentManager.id,
                authenticityToken: authenticityToken,
              },
            });
          }}
        >
          Request Revision
        </button>
      )}
      {showAdminButtons && (
        <button
          className={styles.editBtn}
          onClick={() => {
            approveDocument({
              onResponse,
              onError,
              body: {
                documentManagerId: state.documentManager.id,
                authenticityToken: authenticityToken,
              },
            });
          }}
        >
          Approve
        </button>
      )}
      {showAdminButtons && (
        <button
          className={styles.editBtn}
          onClick={() => {
            denyDocument({
              onResponse,
              onError,
              body: {
                documentManagerId: state.documentManager.id,
                authenticityToken: authenticityToken,
              },
            });
          }}
        >
          Deny
        </button>
      )}
      {showAuthorButtons && (
        <button
          className={styles.editBtn}
          onClick={() => {
            setContentEditable(prevContentEditable => {
              return !prevContentEditable;
            });

            if (contentEditable) {
              saveDocument({
                onResponse,
                onError,
                body: {
                  ...state.documentManager.getCurrentDocument().asJSON(),
                  authenticityToken: authenticityToken,
                },
              });
            }
          }}
        >
          {contentEditable ? 'Save' : 'Edit'}
        </button>
      )}
      {showAuthorButtons && (
        <button
          className={styles.editBtn}
          onClick={() => {
            if (!contentEditable) {
              submitDocument({
                onResponse,
                onError,
                body: {
                  documentManagerId: state.documentManager.id,
                  authenticityToken: authenticityToken,
                },
              });
            } else {
              setState((prevState) => {
                const nextState = {
                  ...prevState,
                  feedbackMessage: 'Save your document before submitting for review.',
                  feedbackVariant: 'error',
                };
          
                return nextState;
              });
            }
          }}
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default DiscoursePublishingWorkflowControls;
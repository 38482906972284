import React, { useState } from 'react';

import {
  EventProvider,
} from './EventContext';

import EventLoader from './EventLoader';
import EventPublishingWorkflowControls from './EventPublishingWorkflowControls';
import EventMainImage from './EventMainImage';
import EventTitle from './EventTitle';
import EventDetails from './EventDetails';
import EventBreakdown from './EventBreakdown';
import EventDescription from './EventDescription';
import DocumentManager from '../../Discourse/components/DocumentManager';
import EventRSVP from './EventRSVP';
import EventAdminSection from './EventAdminSection';
import EventFeedback from './EventFeedback';

const Event = ({
  id,
  authenticatedUser,
  startInEditMode,
  timezones,
}) => {
  const [documentManagerWrapperState, setDocumentManagerWrapperState] = useState({
    documentManager: new DocumentManager({
      id: null,
      startDocumentComponentType: 'DocumentParagraph'
    }),
  });

  return (
    <EventProvider
      authenticatedUser={authenticatedUser}
      id={id}
      startInEditMode={startInEditMode}
      timezones={timezones}
    >
      <EventLoader />
      <EventPublishingWorkflowControls
        documentManagerWrapperState={documentManagerWrapperState}
      />
      <EventMainImage />
      <EventTitle />
      <EventDetails />
      <EventBreakdown />
      <EventDescription
        documentManagerWrapperState={documentManagerWrapperState}
        setDocumentManagerWrapperState={setDocumentManagerWrapperState}
      />
      <EventRSVP />
      <EventAdminSection />
      <EventFeedback />
    </EventProvider>
  );
};

export default Event;

import React, { useState, useEffect } from 'react';
import AdminDiscourseNavigation from './AdminDiscourseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListOfDiscourse from './ListOfDiscourse';
import Feedback from '../../Feedback/components/Feedback';

import {
  getDocumentsForAdmin,
  seedDocument,
} from './restApi';

import styles from './DiscourseIndex.module.scss';

const AdminDiscourse = ({ authenticatedUser }) => {
  const DRAFT_VIEW_STATE = "draft";
  const PENDING_APPROVAL_VIEW_STATE = "pending_approval";
  const PENDING_REVISION_VIEW_STATE = "pending_revision";
  const PUBLISHED_VIEW_STATE = "published";
  const DENIED_VIEW_STATE = "denied";

  const VIEW_STATES = [
    DRAFT_VIEW_STATE,
    PENDING_APPROVAL_VIEW_STATE,
    PENDING_REVISION_VIEW_STATE,
    PUBLISHED_VIEW_STATE,
    DENIED_VIEW_STATE,
  ];

  const [state, setState] = useState({
    documentsByViewState: Object.fromEntries(VIEW_STATES.map(viewState => [viewState, []])),
    pageNumberByViewState: Object.fromEntries(VIEW_STATES.map(viewState => [viewState, 0])),
    moreDocumentsAvailableByViewState: Object.fromEntries(VIEW_STATES.map(viewState => [viewState, true])),
    viewState: PUBLISHED_VIEW_STATE,
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const clearFeedback = () => {
    setState(prevState => ({
      ...prevState,
      feedbackMessage: null,
      feedbackVariant: null,
    }));
  };

  const onResponse = (response) => {
    setState(prevState => {
      const newDocumentsByViewState = {
        ...prevState.documentsByViewState
      };

      newDocumentsByViewState[response.viewState] =
        prevState.documentsByViewState[response.viewState].concat(response.documents);

      const newPageNumberByViewState = {
        ...prevState.pageNumberByViewState
      };

      newPageNumberByViewState[response.viewState] = response.pageNumber;

      const newMoreDocumentsAvailableByViewState = {
        ...prevState.moreDocumentsAvailableByViewState
      };

      newMoreDocumentsAvailableByViewState[response.viewState] = response.moreDocumentsAvailable;

      return {
        ...prevState,
        ...response,
        documentsByViewState: newDocumentsByViewState,
        pageNumberByViewState: newPageNumberByViewState,
        moreDocumentsAvailableByViewState: newMoreDocumentsAvailableByViewState,
      };
    });
  };

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  useEffect(() => {
    getDocumentsForAdmin({
      viewState: state.viewState,
      pageNumber: state.pageNumberByViewState[state.viewState] + 1,
      onResponse,
      onError,
    });
  }, [state.viewState]);

  return (
    <>
      <section className={styles.pageSection}>
        <AdminDiscourseNavigation
          authenticatedUser={authenticatedUser}
          state={state}
          setState={setState}
        />
      </section>
      <InfiniteScroll
        dataLength={state.documentsByViewState[state.viewState]}
        next={() => {
          getDocumentsForAdmin({
            viewState: state.viewState,
            pageNumber: state.pageNumberByViewState[state.viewState] + 1,
            onResponse,
            onError,
          });
        }}
        hasMore={state.moreDocumentsAvailableByViewState[state.viewState]}
        loader={
          <h4 style={{ textAlign: 'center' }}>Loading...</h4>
        }
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>You've seen all available articles</b>
          </p>
        }
        scrollableTarget="html"
      >
        <ListOfDiscourse discourse={state.documentsByViewState[state.viewState]} />
      </InfiniteScroll>
      {authenticatedUser?.canSeedDocument && (
        <div className={styles.articleContainer}>
          <button
            className={styles.seedDocumentBtn}
            onClick={() => seedDocument({
              onResponse,
              onError,
              body: {
                authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
              },
            })}
          >
            Seed Document
          </button>
        </div>
      )}
      <Feedback
        message={state.feedbackMessage}
        variant={state.feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </>
  );
};

export default AdminDiscourse;

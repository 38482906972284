import React from 'react';

import styles from './DiscourseIndex.module.scss';

const ListOfDiscourse = ({ discourse }) => {
  return discourse.map(document => {
    const authorProfilePath = document?.author?.path;
    const authorName = document?.author?.name;
    const localeDateString = () => {
      const date = new Date(Date.parse(document?.publishedAt));
      return date.toLocaleDateString();
    };

    return (
      <div key={document.id} className={styles.articleContainer}>
        <div className={styles.publishingInfoContainer}>
          <p className={styles.publishingInfo}>
            Published on {localeDateString()} by <a id={styles.author} href={authorProfilePath}>{authorName}</a>
          </p>
        </div>
        <a className={styles.articleLink} href={document.indexPath} target="_blank">
          <img className={styles.img} src={document.mainImagePath} />
          <h3 className={styles.articleTitle}>{document.title}</h3>
          <p className={styles.articleSubtitle}>{document.subtitle}</p>
        </a>
      </div>
    );
  });
};

export default ListOfDiscourse;

import React, { useEffect } from 'react';

import {
  useBiopharmaGroupsState,
  useBiopharmaGroupsDispatch
} from './BiopharmaGroupsContext';

import {
  getBiopharmaGroups,
} from './restApi';

import styles from './BiopharmaGroups.module.scss';

const BiopharmaGroupsTable = () => {
  const biopharmaGroupsState = useBiopharmaGroupsState();

  const biopharmaGroupsDispatch = useBiopharmaGroupsDispatch();

  const onResponse = response => biopharmaGroupsDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => biopharmaGroupsDispatch({ type: 'ON_ERROR', feedback });

  const updateNextPageNumber = (e) => {
    biopharmaGroupsDispatch({
      type: 'UPDATE_NEXT_PAGE_NUMBER',
      nextPageNumber: e.target.value,
    });
  };

  useEffect(() => {
    getBiopharmaGroups({
      onResponse,
      onError,
      pageNumber: biopharmaGroupsState.nextPageNumber,
    });
  }, []);

  return biopharmaGroupsState?.biopharmaGroups?.length ? (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Weblinks</th>
            <th>Locations</th>
            <th>Categories</th>
          </tr>
        </thead>
        <tbody>
          {biopharmaGroupsState?.biopharmaGroups?.map(biopharmaGroup => {
            return (
              <tr key={biopharmaGroup.id}>
                <td>{biopharmaGroup.name}</td>
                <td>
                  {biopharmaGroup.weblinks.map((weblink) => {
                    return <a href={weblink.url} target="_blank">{weblink.url}</a>;
                  })}
                </td>
                <td>
                  {biopharmaGroup.locations.map((location) => {
                    return <p>{`${location.municipality}, ${location.state} ${location.country}`}</p>;
                  })}
                </td>
                <td>
                  {biopharmaGroup.categories.map((category) => {
                    return <p>{category.name}</p>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <p>{`Current page number: ${biopharmaGroupsState.pageNumber}`}</p>
        <label className={styles.inputLabel}>Choose Next Page Number</label>
        <input
          className={styles.input}
          type="number"
          min="1"
          max={biopharmaGroupsState.numberOfPages}
          value={biopharmaGroupsState.nextPageNumber}
          onChange={updateNextPageNumber}
        />
        <button
          className={styles.btn}
          onClick={() => {
            getBiopharmaGroups({
              onResponse,
              onError,
              pageNumber: biopharmaGroupsState.nextPageNumber,
            });
          }}
        >
          Get Next Page
        </button>
      </div>
    </>
  ) : (
    <p className={styles.noContentMessage}>No biopharma groups.</p>
  );
};

export default BiopharmaGroupsTable;

import { createContext, useContext, useReducer } from 'react';

const CareerBuilderRequestsStateContext = createContext(null);

const CareerBuilderRequestsDispatchContext = createContext(null);

export function CareerBuilderRequestsProvider({ children }) {
  const initialCareerBuilderRequestsState = {
    careerBuilderRequests: [],
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [careerBuilderRequestsState, careerBuilderRequestsDispatch] = useReducer(
    careerBuilderRequestsReducer,
    initialCareerBuilderRequestsState
  );

  return (
    <CareerBuilderRequestsStateContext.Provider value={careerBuilderRequestsState}>
      <CareerBuilderRequestsDispatchContext.Provider value={careerBuilderRequestsDispatch}>
        {children}
      </CareerBuilderRequestsDispatchContext.Provider>
    </CareerBuilderRequestsStateContext.Provider>
  );
}

export function useCareerBuilderRequestsState() {
  return useContext(CareerBuilderRequestsStateContext);
}

export function useCareerBuilderRequestsDispatch() {
  return useContext(CareerBuilderRequestsDispatchContext);
}

function careerBuilderRequestsReducer(careerBuilderRequestsState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...careerBuilderRequestsState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...careerBuilderRequestsState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...careerBuilderRequestsState, ...clearedFeedback };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

import React, { useEffect } from 'react';

import {
  useEventState,
  useEventDispatch
} from './EventContext';

import {
  getAuthenticatedEventAttendeeByAuthenticatedUser,
  getAuthenticatedEventAttendeeByInviteEmailAndInviteCode,
  acceptEventInvitation,
  declineEventInvitation,
} from './restApi';

import styles from './EventAttendees.module.scss';

const EventRSVP = () => {
  const state = useEventState();

  const eventDispatch = useEventDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => eventDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => eventDispatch({ type: 'ON_ERROR', feedback });

  const rsvpYesButtonStyle = (state?.authenticatedEventAttendee?.rsvpResponse == 'yes') ?
    styles.selectedRSVPBtn :
    styles.unselectedRSVPBtn;

  const rsvpNoButtonStyle = (state?.authenticatedEventAttendee?.rsvpResponse == 'no') ?
    styles.selectedRSVPBtn :
    styles.unselectedRSVPBtn;

  const getAuthenticatedEventAttendee = () => {    
    if (state?.inviteEmail && state?.inviteCode) {
      getAuthenticatedEventAttendeeByInviteEmailAndInviteCode({
        id: state?.event?.id,
        onResponse,
        onError,
        inviteEmail: state?.inviteEmail,
        inviteCode: state?.inviteCode,
      });
    } else {
      getAuthenticatedEventAttendeeByAuthenticatedUser({
        id: state?.event?.id,
        onResponse,
        onError,
      });
    }
  };

  useEffect(() => {
    getAuthenticatedEventAttendee();
  }, []);

  return state?.authenticatedEventAttendee ? (
    <div class={styles.event}>
      <div id={styles.rsvpContainer}>
        <h2 className={styles.tableTitle}>
          RSVP
        </h2>
        <p>Will you attend the event?</p>
        <div>
          <button
            onClick={() => {
              acceptEventInvitation({
                id: state?.event?.id,
                onResponse,
                onError,
                body: {
                  authenticity_token: authenticityToken,
                  event_attendee_id: state?.authenticatedEventAttendee.id,
                },
              });
            }}
            className={rsvpYesButtonStyle}
          >
            Yes
          </button>
          <button
            onClick={() => {
              declineEventInvitation({
                id: state?.event?.id,
                onResponse,
                onError,
                body: {
                  authenticity_token: authenticityToken,
                  event_attendee_id: state?.authenticatedEventAttendee.id,
                },
              });
            }}
            className={rsvpNoButtonStyle}
          >
            No
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default EventRSVP;

import { createContext, useContext, useReducer } from 'react';

const EventStateContext = createContext(null);

const EventDispatchContext = createContext(null);

export function EventProvider({
  authenticatedUser,
  id,
  startInEditMode,
  timezones,
  children
}) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inviteEmail = urlParams.get('invite_email');
  const inviteCode = urlParams.get('invite_code');

  const initialEventState = {
    inEditMode: startInEditMode,
    authenticatedUser,
    loading: true,
    inviteEmail,
    inviteCode,
    event: {
      id: id,
      image: null,
      imageFileName: null,
      title: null,
      shortDescription: null,
      startYear: null,
      startMonth: null,
      startDay: null,
      endYear: null,
      endMonth: null,
      endDay: null,
      startHour: null,
      startMinute: null,
      endHour: null,
      endMinute: null,
      timezoneName: 'Eastern Time (US & Canada)',
      venueType: 'in-person',
      location: null,
      breakdownItems: [],
      description: null,
    },
    newEventBreakdownItem: {
      startYear: null,
      startMonth: null,
      startDay: null,
      endYear: null,
      endMonth: null,
      endDay: null,
      startHour: null,
      startMinute: null,
      endHour: null,
      endMinute: null,
      description: null,
      ordinal: 1,
    },
    newEventAttendee: {
      firstName: '',
      lastName: '',
      email: '',
    },
    authenticatedEventAttendee: null,
    feedbackMessage: null,
    feedbackVariant: null,
    timezones,
  };

  const [eventState, eventDispatch] = useReducer(
    eventReducer,
    initialEventState
  );

  return (
    <EventStateContext.Provider value={eventState}>
      <EventDispatchContext.Provider value={eventDispatch}>
        {children}
      </EventDispatchContext.Provider>
    </EventStateContext.Provider>
  );
}

export function useEventState() {
  return useContext(EventStateContext);
}

export function useEventDispatch() {
  return useContext(EventDispatchContext);
}

function eventReducer(eventState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...eventState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...eventState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...eventState, ...clearedFeedback };
    }
    case 'UPDATE_IMAGE_AND_IMAGE_FILE_NAME': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          image: action.image,
          imageFileName: action.imageFileName,
        }
      };
    }
    case 'UPDATE_TITLE': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          title: action.title,
        }
      };
    }
    case 'UPDATE_SHORT_DESCRIPTION': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          shortDescription: action.shortDescription,
        }
      };
    }
    case 'UPDATE_START_DATE': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          startYear: action.startYear,
          startMonth: action.startMonth,
          startDay: action.startDay,
        }
      };
    }
    case 'UPDATE_END_DATE': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          endYear: action.endYear,
          endMonth: action.endMonth,
          endDay: action.endDay,
        }
      };
    }
    case 'UPDATE_START_TIME': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          startHour: action.startHour,
          startMinute: action.startMinute,
        }
      };
    }
    case 'UPDATE_END_TIME': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          endHour: action.endHour,
          endMinute: action.endMinute,
        }
      };
    }
    case 'UPDATE_TIMEZONE': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          timezoneName: action.timezoneName,
        }
      };
    }
    case 'UPDATE_LOCATION': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          location: action.location,
        }
      };
    }
    case 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_START_DATE': {
      return {
        ...eventState,
        newEventBreakdownItem: {
          ...eventState.newEventBreakdownItem,
          startYear: action.startYear,
          startMonth: action.startMonth,
          startDay: action.startDay,
        }
      };
    }
    case 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_END_DATE': {
      return {
        ...eventState,
        newEventBreakdownItem: {
          ...eventState.newEventBreakdownItem,
          endYear: action.endYear,
          endMonth: action.endMonth,
          endDay: action.endDay,
        }
      };
    }
    case 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_START_TIME': {
      return {
        ...eventState,
        newEventBreakdownItem: {
          ...eventState.newEventBreakdownItem,
          startHour: action.startHour,
          startMinute: action.startMinute,
        }
      };
    }
    case 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_END_TIME': {
      return {
        ...eventState,
        newEventBreakdownItem: {
          ...eventState.newEventBreakdownItem,
          endHour: action.endHour,
          endMinute: action.endMinute,
        }
      };
    }
    case 'UPDATE_NEW_EVENT_BREAKDOWN_ITEM_DESCRIPTION': {
      return {
        ...eventState,
        newEventBreakdownItem: {
          ...eventState.newEventBreakdownItem,
          description: action.description,
        }
      };
    }
    case 'ADD_NEW_EVENT_BREAKDOWN_ITEM': {
      return {
        ...eventState,
        event: {
          ...eventState.event,
          breakdownItems: eventState.event.breakdownItems.concat([eventState.newEventBreakdownItem]),
        },
        newEventBreakdownItem: {
          startDay: null,
          endDay: null,
          startHour: null,
          startMinute: null,
          endHour: null,
          endMinute: null,
          description: null,
          ordinal: eventState.newEventBreakdownItem.ordinal + 1,
        },
      };
    }
    case 'UPDATE_FIRST_NAME': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          firstName: action.firstName,
        }
      };
    }
    case 'UPDATE_LAST_NAME': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          lastName: action.lastName,
        }
      };
    }
    case 'UPDATE_EMAIL': {
      return {
        ...eventState,
        newEventAttendee: {
          ...eventState.newEventAttendee,
          email: action.email,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

import { useEffect } from 'react';

import {
  useEventState,
  useEventDispatch,
} from './EventContext';

import { getEvent } from './restApi';

const EventLoader = () => {
  const state = useEventState();
  const dispatch = useEventDispatch();

  const onResponse = response => dispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => dispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    if (state.event.id) {
      getEvent({
        id: state.event.id,
        onResponse,
        onError,
      });
    }
  }, []);

  return null;
};

export default EventLoader;

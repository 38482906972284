import React from 'react';

import {
  monthNameFromNumber,
  meridiemIndicatorForHour,
  hourForTwelveHourClock,
  formattedMinute,
} from '../../Shared/timeHelpers';

import styles from './EventPost.module.scss';

const EventPost = ({
  event
}) => {
  const goToEvent = () => {
    window.location.assign(event.path);
  };

  return (
    <div className={styles.eventPost} onClick={goToEvent}>
      <img className={styles.img} src={event.imagePath} />
      <div className={styles.textContainer}>
        <p className={styles.title}>{event.title}</p>
        <p className={styles.date}>{`📅 Date: ${monthNameFromNumber(event.startMonth)} ${event.startDay}, ${event.startYear}`}</p>
        <p className={styles.time}>{`🕕 Time: ${hourForTwelveHourClock(event.startHour)}:${formattedMinute(event.startMinute)} ${meridiemIndicatorForHour(event.startHour)} - ${hourForTwelveHourClock(event.endHour)}:${formattedMinute(event.endMinute)} ${meridiemIndicatorForHour(event.endHour)} ${event.timezoneName}`}</p>
        <p className={styles.location}>{`📍 ${event.location}`}</p>
        <p className={styles.shortDescription}>{event.shortDescription}</p>
      </div>
    </div>
  );
};

export default EventPost;

import { createContext, useContext, useReducer } from 'react';

const BiopharmaGroupsStateContext = createContext(null);

const BiopharmaGroupsDispatchContext = createContext(null);

export function BiopharmaGroupsProvider({ children }) {
  const initialBiopharmaGroupsState = {
    loading: true,
    pageNumber: null,
    nextPageNumber: 1,
    numberOfPages: 1,
    biopharmaGroups: [],
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [biopharmaGroupsState, biopharmaGroupsDispatch] = useReducer(
    biopharmaGroupsReducer,
    initialBiopharmaGroupsState
  );

  return (
    <BiopharmaGroupsStateContext.Provider value={biopharmaGroupsState}>
      <BiopharmaGroupsDispatchContext.Provider value={biopharmaGroupsDispatch}>
        {children}
      </BiopharmaGroupsDispatchContext.Provider>
    </BiopharmaGroupsStateContext.Provider>
  );
}

export function useBiopharmaGroupsState() {
  return useContext(BiopharmaGroupsStateContext);
}

export function useBiopharmaGroupsDispatch() {
  return useContext(BiopharmaGroupsDispatchContext);
}

function biopharmaGroupsReducer(biopharmaGroupsState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...biopharmaGroupsState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...biopharmaGroupsState, ...action.feedback };
    }
    case 'UPDATE_NEXT_PAGE_NUMBER': {
      return {
        ...biopharmaGroupsState,
        nextPageNumber: action.nextPageNumber,
      }
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...biopharmaGroupsState, ...clearedFeedback };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
